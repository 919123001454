import React from 'react';
import { Link } from 'gatsby';

import LoginPic from '../assets/images/login-picture.jpg';
import LoginSubscriptionExpired from '../components/LoginSubscriptionExpired';
import Login from '../components/Login';
import Footer from '../components/Footer';

const LoginSubExpiredPage = () => {
  return (
    <>
      <section className="section">
        <div className="section-wrapper_outer">
          <div className="section-img">
            <img src={LoginPic} alt="" />
            <h1>Your bridge to better</h1>
          </div>
          <div className="section-wrapper_inner">
            <div className="section-container">
              <div className="section-container-inner">
                <LoginSubscriptionExpired />
              </div>
              <div className="section-container-inner">
                <Login />
              </div>
              <div className="section-container-inner">
                <Footer />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default LoginSubExpiredPage;
