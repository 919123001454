import React, { useState, useCallback, useEffect } from 'react';
import { Link } from 'gatsby';
import { useForm } from 'react-hook-form';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import ErrorContextConsumer from './ErrorContext';

import Requests from '../actions';
import { REDIRECT_URL, IS_CLIENT } from '../constants';
import IconUser from '../assets/images/ic-user.inline.svg';
import IconPassword from '../assets/images/ic-pass.inline.svg';
import IconEyePass from '../assets/images/ic-eye.inline.svg';
import Button from './Button';
import LoginTitle from './LoginTitle';

const TERMS_ACCEPTED_KEY = 'termsAccepted';

export const lsSetTermsAccepted = () => {
  try {
    localStorage.setItem(TERMS_ACCEPTED_KEY, true);
  } catch (e) {
    return null;
  }
}

const lsGetTermsAccepted = () => {
  try {
    return JSON.parse(localStorage.getItem(TERMS_ACCEPTED_KEY))
  } catch (e) {
    return false;
  }
}

const Login = (props) => {
  const { loader } = props;
  const { register, handleSubmit, watch, errors } = useForm();
  const [isShowingPassword, setIsShowingPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [termsChecked, setTermsChecked] = useState(false);

  const autoLoginRedirect = (autologinUrl) => {
    setLoading(true);
    if (autologinUrl && IS_CLIENT) {
      window.location = `${REDIRECT_URL}${autologinUrl}`;
    }
  };

  useEffect(() => {
    setLoading(loader);
  }, [loader]);

  useEffect(() => {
    setTermsChecked(lsGetTermsAccepted());
  }, []);

  const loginApiCall = useCallback(async (data) => {
    setLoading(true);
    const { autologinUrl, isNewUser, errorMessage } = await Requests.signIn(data);
    setLoading(false);

    if (errorMessage) {
      return toast.error(errorMessage, {
        hideProgressBar: true,
        className: 'toast',
      });
    }

    autoLoginRedirect(autologinUrl);
    lsSetTermsAccepted();
  });

  return (
    <section className="login">
      <div className="login-wrapper_outer">
        <div className="login-title_wrapper">
          <LoginTitle />
          <p className="login-title_par">Please enter your username and password.</p>
        </div>
        <form onSubmit={handleSubmit(loginApiCall)}>
          <div className="login-form_wrapper__outer">
            <div className="login-form_container__username">
              <div
                className={`login-form_container_input ${
                  errors.username || errors.password ? 'login-form_container_input--error' : ''
                }`}
              >
                <IconUser className={watch('username') ? 'active-svg' : ''} />
                <span>
                  <Link to="/forgotuser">Forgot ?</Link>
                </span>
                <input
                  type="text"
                  className="login-input__username"
                  placeholder="Login"
                  name="username"
                  ref={register({ required: true })}
                />
              </div>
            </div>
            <div className="login-form_container__password">
              <div
                className={`login-form_container_input ${
                  errors.username || errors.password ? 'login-form_container_input--error' : ''
                }`}
              >
                <IconEyePass
                  className="icon-eye"
                  onClick={() => setIsShowingPassword(!isShowingPassword)}
                />
                <IconPassword className={watch('password') ? 'active-svg' : ''} />
                <input
                  type={isShowingPassword ? 'text' : 'password'}
                  placeholder="Password"
                  className="login-input__password"
                  name="password"
                  ref={register({ required: true })}
                />
              </div>
            </div>
          </div>
          <div className="login-form_wrapper__inner">
            <div className="login-form_container">
              <div className="login-form_checkbox">
                <input type="checkbox" id="rememberME" name="rememberMe" ref={register()} />
                <label htmlFor="rememberME">Remember Me</label>
              </div>
              <Link to="/forgotpass/">Forgot your password?</Link>
            </div>
            <div className="login-form_container">
              <div className="login-form_checkbox">
                <input type="checkbox" id="terms" checked={termsChecked} onChange={e => setTermsChecked(e.target.checked)}/>
                <label htmlFor="terms" className="terms-label">
                  I have read and accept&nbsp;<a href='/terms-and-conditions' className='terms-link' target='_blank'>terms and conditions</a>
                </label>
              </div>
            </div>
            <Button title="Sign in" loading={loading} disabled={!termsChecked} />
          </div>
        </form>
      </div>
      <ToastContainer />
    </section>
  );
};

const LoginContainer = (props) => (
  <ErrorContextConsumer>{({ errorMsg, set }) => <Login loader={props.loader} setErrorMsg={set} />}</ErrorContextConsumer>
);

export default LoginContainer;
